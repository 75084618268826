<template>
    <div class="qualification">
        <div class="content">
            <div class="title">资质资信</div>
            <ul class="tabs">
                <li
                    v-for="item in tabs"
                    :key="item.guid"
                    @click="changeTab(item)"
                    :style="{ borderColor: activeName === item.guid ? '#e91b2b' : 'transparent'}"
                >
                    {{ item.title }}
                </li>
            </ul>
            <div class="zzzx_cont">
                <div
                    class="zzzx_text"
                    v-html="activeObj.content"
                ></div>
                <img
                    :src="activeObj.remark"
                    v-if="activeObj.remark"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'qualification',
    props: {
        data: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            tabs: [],
            activeName: '',
            activeObj: {},
        };
    },
    watch: {
        data: {
            handler(data) {
                if (this.data && this.data.length > 0) {
                    this.tabs = this.data;
                    this.activeObj = this.tabs[0] ? this.tabs[0] : {};
                    this.activeName = this.tabs[0] ? this.tabs[0].guid : '';
                }
            },
            immediate: true,
        },
    },
    methods: {
        changeTab(item) {
            this.activeName = item.guid;
            this.activeObj = item;
        },
    },
    mounted() {
        this.$nextTick(() => {});
    },
    computed: {},
};
</script>
<style scoped lang="scss">
.qualification {
    background: #fff;
    padding: 64px 0px 44px 0px;

    .title {
        font-size: 32px;
        letter-spacing: 3px;
        color: #252b3a;
        text-align: center;
        margin-bottom: 55px;
    }
    .zzzx_cont {
        display: flex;
        padding: 22px 55px 22px 22px;
        justify-content: space-between;
        .zzzx_text {
            padding: 50px 22px 20px 54px;
        }

        img {
            // width: 220px;
            height: 320px;
        }
    }
    .tabs {
        border-bottom: 1px solid #e4e9f0;
        display: flex;
        justify-content: space-between;
        font-size: 18px;
        line-height: 72px;
        letter-spacing: 1px;
        color: #252b3a;
        padding: 0px 220px;
        user-select: none;
        li {
            border-bottom: 3px solid transparent;
            padding: 0px 20px;
            cursor: pointer;
        }
    }
}
</style>
