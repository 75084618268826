<template>
    <div class="video_bit">
        <div class="content">
            <div 
            class="video-placeholder"
            v-show="pauseState"
            @click="playHandler"
            >
                <img 
                    :src="require('@/static/images/home/9.jpg')"
                />

                <img 
                    class="icon-play"
                    src="../assets/images/icon-play.png" 
                    alt=""
                />
            </div>

             <video 
                ref="video"
                v-show="!pauseState"
                class="video"
                src="/movie.mp4"
                controls
             ></video>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            pauseState: true,
        };
    },
    created() {},
    methods: {
        playHandler() {
            this.pauseState = false;
            this.$refs.video.play();
        },
    },
    mounted() {},
    computed: {},
};
</script>
<style scoped lang="scss">
$fullHeight: 675px;

.video_bit {
    background: #fff;

    .video-placeholder{
        position: relative;
        cursor: pointer;

        .icon-play{
            width: 60px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: .6;
            transition: all .3s;
        }

        &:hover {
            .icon-play{
                opacity: .9;
            }
        }
    }

    .video{
        width: 100%;
    }
}
</style>
