<template>
    <div class="content">
        <div class="star_supplier">
            <div class="title">星级供应商</div>

            <el-carousel
                arrow="always"
                :height="'130px'"
                @change="changeHandler"
                indicator-position="outside"
                :interval="5000"
            >
                <el-carousel-item
                    v-for="item in total"
                    :key="item"
                    class="czzx_cont"
                >
                    <img
                        :src="item.remark"
                        :alt="item.title"
                        v-for="item in suppliers"
                        :key="item.guid"
                        @click="clickUrl(item)"
                    >
                    <img
                        class="img_none"
                        v-for="(item,i) in (4 - suppliers.length)"
                        :key="i"
                    />

                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
import { isBetween } from '@js/util.js';
export default {
    name: 'star_supplier',
    props: {
        data: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            suppliers: [],
            total: 0,
            filterData: [],
        };
    },
    watch: {
        data: {
            handler(data) {
                let filterData = data.filter((r) => {
                    return isBetween(r.startTime, r.endTime);
                });
                this.filterData = filterData;
                if (filterData && filterData.length > 0) {
                    this.suppliers = filterData.slice(0, 4);
                    this.total = Math.ceil(filterData.length / 4);
                }
            },
            immediate: true,
        },
    },
    methods: {
        clickUrl(item) {
            window.open(item.url);
        },
        changeHandler(index) {
            this.suppliers = this.filterData.slice(index * 4, (index + 1) * 4);
        },
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
.star_supplier {
    padding: 64px 0px;
    .title {
        font-size: 32px;
        letter-spacing: 3px;
        color: #252b3a;
        text-align: center;
        margin-bottom: 55px;
    }
    .czzx_cont {
        display: flex;
        justify-content: space-between;
        img {
            width: 282px;
            height: 120px;
            cursor: pointer;
        }
        .img_none {
            width: 282px;
            height: 0px;
        }
    }
}
</style>
