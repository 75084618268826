<template>
    <div class="links">
        <div class="content">
            <div class="title">友情链接</div>
            <div
                v-for="item in linksData"
                :key="item.type"
            >
                <div class="links_title">{{ item.type }}</div>
                <div class="links_company">
                    <div
                        class="links_name poptip"
                        v-for="itemA in item.data"
                        :key="itemA.title"
                        :href="itemA.url"
                        @click="openUrl(itemA.url)"
                        :aria-controls="itemA.title"
                    >
                        {{ itemA.title }}
                    </div>
                    <a class="links_name"></a>
                    <a class="links_name"></a>
                    <a class="links_name"></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { isBetween } from '@js/util.js';
export default {
    name: 'links',
    data() {
        return {
            linksData: [],
        };
    },
    methods: {
        openUrl(url) {
            window.open(url);
        },
    },
    mounted() {
        this.$nextTick(() => {
            let links = this.$store.state.config.friendshiplink;
            // 筛选是否过期
            let newLinks = [];
            links.map((r) => {
                let data = [];
                r.data.map((p) => {
                    if (isBetween(p.startTime, p.endTime)) {
                        data.push(p);
                    }
                });

                newLinks.push({
                    type: r.type,
                    data: data,
                });
            });
            this.linksData = newLinks;
        });
    },
    computed: {},
};
</script>
<style scoped lang="scss">
@import '@css/var.scss';
.links {
    padding: 64px 0px;
    background: #fff;

    .title {
        font-size: 32px;
        letter-spacing: 3px;
        color: #252b3a;
        text-align: center;
        margin-bottom: 55px;
    }
    .links_title {
        font-size: 18px;
        letter-spacing: 1px;
        color: #252b3a;
        font-weight: 600;
        margin-bottom: 24px;
        margin-top: 30px;
    }
    .links_company {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .links_name {
            line-height: 25px;
            margin-bottom: 10px;
            width: 23.5%;
            font-size: 14px;
            letter-spacing: 1px;
            color: #575d6c;
            cursor: pointer;
            // word-break: break-all;
            // text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-box-orient: vertical;
            // -webkit-line-clamp: 2; /* 这里是超出几行省略 */
            // overflow: hidden;

            word-break: break-all;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
                color: $primaryColor;
            }
        }
    }
}
</style>
