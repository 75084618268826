<template>
    <div
        class="top"
        v-if="showAd"
    >
        <div class="content">
            <div>{{data.title}} <label
                    @click="jump"
                    class="next"
                >>>></label>
            </div>
            <div>请认准建投商务网唯一官方网址：https://www.jtsww.com/ <i
                    class="el-icon-close"
                    @click="close"
                ></i></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: [Object, Boolean],
            default: false,
        },
    },
    data() {
        return {
            showAd: false,
        };
    },
    watch: {
        data: {
            handler(val) {
                if (val) {
                    document.getElementsByClassName('header')[0].style.marginTop = '50px';
                    this.showAd = true;
                } else {
                    this.close();
                }
            },
            immediate: true,
        },
    },
    beforeDestroy() {
        this.close();
    },
    mounted() {},
    methods: {
        close() {
            document.getElementsByClassName('header')[0].style.marginTop = '0px';
            this.showAd = false;
        },
        jump() {
            window.open('/news/detail?id=' + this.data.guid + '&t=ad');
        },
    },
};
</script>
<style lang='scss' scoped>
@import '@css/var.scss';
.top {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 50px;
    background: #fdebeb;
    width: 100%;
    line-height: 50px;
    .content {
        display: flex;
        justify-content: space-between;
        color: $primaryColor;
        i {
            margin-left: 5px;
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;
        }
        .next {
            letter-spacing: -3px;
            cursor: pointer;
        }
    }
}
</style>
