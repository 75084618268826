export function isBetween(startTime, endTime) {
    let nowdate = new Date();

    var startTime = new Date(startTime);

    var endTime = new Date(endTime);

    if (!(nowdate > endTime || nowdate < startTime)) {
        return true;
    }
}