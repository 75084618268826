<template>
    <div
        id="thediv"
        ref="thediv"
        v-show="showAd"
        style="z-index: 999; left: 0px; position: absolute; top: 0px;"
        @mouseover="pause_resume"
        @mouseleave="start"
    >
        <a
            :href="url"
            target="_blank"
        >
            <img :src="imgSrc" />
        </a>

        <i
            class="el-icon-error"
            @click="close"
        ></i>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: [Object, Boolean],
            default: false,
        },
    },
    watch: {
        data: {
            handler(data) {
                if (data) {
                    this.url = this.data.url;
                    this.imgSrc = this.data.remark;
                    this.showAd = true;
                } else {
                    this.showAd = false;
                }
            },
            immediate: true,
        },
    },
    data() {
        return {
            xPos: 0,
            yPos: 0,
            step: 1,
            delay: 18,
            height: 0,
            Hoffset: 0,
            Woffset: 0,
            yon: 0,
            xon: 0,
            interval: null,
            showAd: false,
            url: '',
            imgSrc: '',
            // src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
        };
    },
    beforeDestroy() {
        this.pause_resume();
    },
    mounted() {
        this.start();
    },
    methods: {
        close() {
            this.showAd = false;
            this.pause_resume();
        },
        changePos() {
            let width = document.documentElement.clientWidth;
            let height = document.documentElement.clientHeight;
            this.Hoffset = this.$refs.thediv.offsetHeight; //获取元素高度
            this.Woffset = this.$refs.thediv.offsetWidth;

            // 滚动部分跟随屏幕滚动
            // this.$refs.thediv.style.left =
            //     this.xPos +
            //     document.body.scrollLeft +
            //     document.documentElement.scrollLeft +
            //     'px';
            // this.$refs.thediv.style.top =
            //     this.yPos +
            //     document.body.scrollTop +
            //     document.documentElement.scrollTop +
            //     'px';

            // 滚动部分不随屏幕滚动
            this.$refs.thediv.style.left =
                this.xPos + document.body.scrollLeft + 'px';
            this.$refs.thediv.style.top =
                this.yPos + document.body.scrollTop + 'px';

            if (this.yon) {
                this.yPos = this.yPos + this.step;
            } else {
                this.yPos = this.yPos - this.step;
            }
            if (this.yPos < 0) {
                this.yon = 1;
                this.yPos = 0;
            }
            if (this.yPos >= height - this.Hoffset) {
                this.yon = 0;
                this.yPos = height - this.Hoffset;
            }

            if (this.xon) {
                this.xPos = this.xPos + this.step;
            } else {
                this.xPos = this.xPos - this.step;
            }
            if (this.xPos < 0) {
                this.xon = 1;
                this.xPos = 0;
            }
            if (this.xPos >= width - this.Woffset) {
                this.xon = 0;
                this.xPos = width - this.Woffset;
            }
        },
        start() {
            this.interval = setInterval(this.changePos, this.delay);
        },
        pause_resume() {
            clearInterval(this.interval);
        },
    },
};
</script>
<style lang='scss' scoped>
.closeBtn {
    position: absolute;
    right: 10px;
    top: 0px;
    color: #000;
    font-size: 24px;
    cursor: pointer;
    user-select: none;
    z-index: 1000;
    &:hover {
        color: red;
    }
}

i {
    display: block;
    color: #aaaaaa;
    font-size: 18px;
    margin-top: 7px;
    text-align: center;
    cursor: pointer;
}
</style>
