<template>
    <div>

        <section-banner :list="bannerList"></section-banner>

        <div class="container">
            <!-- 信息介绍 -->
            <info></info>
            <!-- 招标采购 -->
            <bidding></bidding>
            <!-- 操作中心 -->
            <operation-center></operation-center>
            <!-- 星级供应商 -->
            <supplier :data="supplier"></supplier>
            <!-- 资质资信 -->
            <qualification :data="qualification"></qualification>
            <!-- 视频 -->
            <video-bit></video-bit>
            <!-- 友情链接 -->
            <links></links>
        </div>
        
        <client-only>
            <!-- 顶部弹框 -->
            <top-ad :data="topAdData"></top-ad>

            <!-- 悬浮 -->
            <!-- <float-ad :data="floatAdData"></float-ad> -->

            <!-- 弹框 -->
            <bullet-frame :data="bulletFrameData"></bullet-frame>
            
            <!-- 漂浮 -->
            <suspension :data="floatAdData"></suspension>
        </client-only>
    </div>
</template>

<script>
import SectionBanner from './index/sections/banner';

import info from './index/sections/info.vue';
import bidding from './index/sections/bidding.vue';
import operationCenter from './index/sections/operation-center.vue';
import supplier from './index/sections/supplier.vue';
import qualification from './index/sections/qualification.vue';
import links from './index/sections/links.vue';
import videoBit from './index/sections/video-bit.vue';
import BulletFrame from './index/components/ad/bullet-frame.vue';
// import FloatAd from './index/components/ad/float-ad.vue';
import TopAd from './index/components/ad/top-ad.vue';
import suspension from './index/components/ad/suspension.vue';
import appNode from '@js/app-node';

import { isBetween } from '@js/util.js';

export default {
    components: {
        SectionBanner,
        info,
        bidding,
        operationCenter,
        supplier,
        qualification,
        links,
        videoBit,
        BulletFrame,
        TopAd,
        suspension,
    },
    async asyncData(context) {
        let [data] = await Promise.all([
            appNode.ssrReq
                .call(
                    context,
                    `${context.store.state.api.staticdataUrl}/portalinfo.json`
                )
                .then((data) => {
                    context.store.commit('setLinks', data.friendshiplink);
                    return data;
                }),

            appNode.ssrReq
                .call(
                    context,
                    `${context.store.state.api.staticdataUrl}/portalbasicinfo.json`
                )
                .then((data) => {
                    context.store.commit('setBaseInfo', data);
                    return data;
                }),
        ]);

        var {supplier, qualification, banner, notice} = data;

        // banner是否过期
        var bannerList = banner.reduce((arr, item) => {
            if (isBetween(item.startTime, item.endTime)) {
                arr.push(item);
            }

            return arr;
        }, []);

        // 文章是否过期
        var noticeList = notice.reduce((arr, item) => {
            if (isBetween(item.startTime, item.endTime)) {
                arr.push(item);
            }

            return arr;
        }, []);

        return {
            supplier,
            qualification,

            bannerList,
            topAdData: noticeList.find((r) => r.typeCode == 'top'),
            floatAdData: noticeList.find((r) => r.typeCode == 'float'),
            bulletFrameData: noticeList.find((r) => r.typeCode == 'popup'),
        };
    },
    head() {
        return {
            meta: [
                {
                    name: 'description',
                    content: this.$store.state.config.baseInfo.description,
                },
                {
                    name: 'keywords',
                    content: this.$store.state.config.baseInfo.keywords,
                },
            ],
        };
    },
    data: () => ({
        
    }),
    methods: {
        
    },
    created() {
        
    },
};
</script>

<style lang="scss" scoped>
section {
    input {
        padding: 0 1em;
        border-radius: 4px;
    }
}
</style>
