<template>
    <div class="info">
        <div class="content">
            <div
                class="info_block"
                v-for="item in infoData"
                :key="item.title"
            >
                <img
                    :src="item.img"
                    class="info_img"
                />
                <div class="info_cont">
                    <div class="info_title">{{ item.title }}</div>
                    <div class="info_text">{{ item.text }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'info',
    data() {
        return {
            infoData: [
                {
                    img: require('@/static/images/home/zijin.png'),
                    title: '资金保障',
                    text: '为用户合法权益保驾护航',
                },
                {
                    img: require('@/static/images/home/bianjie.png'),
                    title: '便捷高效',
                    text: '全流程线上互联网招标采购平台',
                },
                {
                    img: require('@/static/images/home/zijin.png'),
                    title: '优质服务',
                    text: '7*24小时快速响应服务',
                },
                {
                    img: require('@/static/images/home/xinxi.png'),
                    title: '信息安全',
                    text: '确保对用户的信息安全等全方位把控',
                },
            ],
        };
    },
    created() {},
    methods: {},
    mounted() {},
    computed: {},
};
</script>
<style scoped lang="scss">
.info {
    width: 100%;
    height: 100px;
    background: #fff;
    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        .info_block {
            display: flex;
            align-items: center;
            .info_cont {
                margin-left: 10px;
                .info_title {
                    font-size: 18px;
                    color: #252b3a;
                }
                .info_text {
                    line-height: 27px;
                    font-size: 14px;
                    color: #575d6c;
                }
            }
        }
    }
}
</style>
