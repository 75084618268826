<template>
    <div class="operation_center">
        <div class="title">操作中心</div>
        <div class="content">
            <div class="czzx_cont">
                <div
                    class="czzx_img"
                    v-for="item in data"
                    :key="item.title"
                    :style="{ background: 'url(' + item.img + ')' }"
                    @mouseenter="enterImg(item)"
                    @mouseleave="leaveImg(item)"
                    @click="jump(item)"
                >
                    <div
                        class="mask"
                        v-if="currentItem == item && show"
                    >
                        <div class="mask_title">{{ currentItem.subTitle }}</div>
                        <div class="mask_text">{{ currentItem.text }}</div>
                        <a class="mask_detail">了解详情 ></a>
                    </div>
                    {{ item.title }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'operation_center',
    data() {
        return {
            data: [
                {
                    img: require('@/static/images/home/1.jpg'),
                    title: '非招标采购流程',
                    subTitle: '非招标采购流程',
                    href: '#projectPurchase',
                    // text: '采购流程',
                },
                {
                    img: require('@/static/images/home/4.jpg'),
                    href: '#supplierReg',
                    title: '供应商注册流程',
                    subTitle: '供应商注册流程',
                },
                {
                    img: require('@/static/images/home/3.jpg'),
                    href: '#purchaserReg',
                    title: '采购商注册流程',
                    subTitle: '采购商注册流程',
                },
                {
                    img: require('@/static/images/home/cz_01.png'),
                    href: '#biddingPurchase',
                    title: '投标人投标流程',
                    subTitle: '投标人投标流程',
                },
                {
                    img: require('@/static/images/home/2.jpg'),
                    href:'#CAProcess',
                    title: '数字证书流程',
                    subTitle: '数字证书流程',
                },
            ],
            show: false,
            currentItem: {},
        };
    },
    created() {},
    methods: {
        jump(item) {
            if(item.href.indexOf('#') !== -1){
                window.open('operation' + item.href, '_blank');
            }else{
                this.goto(item.href)
            }
        },
        enterImg(item) {
            this.show = true;
            this.currentItem = item;
        },
        leaveImg(item) {
            this.currentItem = {};
            this.show = false;
        },
    },
    mounted() {},
    computed: {},
};
</script>
<style scoped lang="scss">
@import '@css/var.scss';
.operation_center {
    padding: 64px 0px;
    background: #fff;

    .title {
        font-size: 32px;
        letter-spacing: 3px;
        color: #252b3a;
        text-align: center;
        margin-bottom: 55px;
    }
    .czzx_cont {
        display: flex;
        justify-content: space-between;

        .czzx_img {
            background-size: 100% 100%;
            width: 230px;
            height: 300px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'SourceHanSansCN-Medium';
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;
            position: relative;

            .mask {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(
                    to right,
                    $primaryColor 0%,
                    $primaryColor6 100%
                );
                padding: 90px 30px;
                .mask_title {
                    line-height: 50px;
                }
                .mask_text {
                    font-size: 14px;
                    font-weight: normal;
                    line-height: 24px;
                }
                .mask_detail {
                    font-size: 16px;
                    font-weight: normal;
                    line-height: 50px;
                    color: #fff;
                }
            }
        }
    }
}
</style>
