<template>
    <div
        class="bulletFrame"
        v-if="showAd"
    >
        <div class="dialog">
            <i
                class="el-icon-error close"
                @click="showAd = false"
            ></i>

            <img :src="data.remark" />
            <div
                class="btn"
                @click="jump"
            >邀您填写表单</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: [Object, Boolean],
            default: false,
        },
    },
    watch: {
        data: {
            handler(data) {
                if (data) {
                    this.showAd = true;
                } else {
                    this.showAd = false;
                }
            },
            immediate: true,
        },
    },
    data() {
        return {
            showAd: false,
        };
    },
    mounted() {},
    methods: {
        jump() {
            window.open(this.data.url);
        },
    },
};
</script>
<style lang='scss' scoped>
.bulletFrame {
    display: flex;
    z-index: 9999;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    .dialog {
        // display: flex;
        // display: block;
        // margin: 0 auto 50px;
        // background: #fff;
        box-sizing: border-box;
        // width: 760px;
        // height: 450px;
        // margin-top: 20vh;
        border-radius: 15px;
        border-top-right-radius: 130px;

        position: relative;

        .close {
            position: absolute;
            right: 0px;
            color: #909090;
            font-size: 25px;
            cursor: pointer;
            z-index: 9999;
        }

        img {
            border-radius: 10px;
            border-top-right-radius: 120px;
        }

        .btn {
            width: 220px;
            height: 50px;
            line-height: 50px;
            color: #fff;
            font-size: 16px;
            letter-spacing: 2px;
            text-align: center;
            border-radius: 100px;
            background-image: linear-gradient(to right, #ff8974, #ff6b91);
            box-shadow: 0px 2px 0px #f26e58;
            cursor: pointer;
            position: absolute;
            bottom: 80px;
            right: 15%;
        }
    }
}
</style>
