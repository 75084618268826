<template>
    <section class="banner">
        <el-carousel
            :interval="5000"
            height="550px"
        >
            <el-carousel-item
                v-for="(item, index) in list"
                :key="index"
            >
                <div
                    class="img-con"
                    @click="jump(item.url)"
                    :style="{cursor: item.url ? 'pointer':''}"
                >
                    <img
                        :src="item.remark"
                        :alt="item.title"
                    />
                </div>
            </el-carousel-item>
        </el-carousel>

        <div class="userinfo">
            <client-only>
                <login v-if="!token"></login>

                <user-info
                    v-else
                    @logout="clearTokenState"
                ></user-info>
            </client-only>
        </div>
    </section>
</template>

<script>
import Login from '../components/login/index.vue';
import UserInfo from '../components/user-info';

export default {
    components: {
        Login,
        UserInfo,
    },
    props: {
        list: {
            type: Array,
            default: () => [
                {
                    remark: 'images/banner/home.jpg',
                    title: '建投商务网',
                },
            ],
        },
    },
    data() {
        return {
            token: this.getToken()
        };
    },
    methods: {
        jump(url) {
            if (url) {
                window.open(url);
            }
        },
        clearTokenState() {
            this.token = '';
        },
    },
};
</script>

<style lang="scss" scoped>
$height: 550px;

$infoWidth: 376px;
$centerLeft: 600px - $infoWidth;

.banner {
    height: $height;
    position: relative;

    .img-con {
        height: $height;
        position: relative;

        img {
            height: $height;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .userinfo {
        position: absolute;
        left: calc(50% + $centerLeft);
        top: 50%;
        transform: translateY(-50%);
        z-index: 99;
        width: 376px;
        background: white;
        border-radius: 8px;
        padding: 30px 40px 20px;
        box-sizing: border-box;
    }
}
</style>